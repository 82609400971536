@import url('https://fonts.googleapis.com/css2?family=IM+Fell+English+SC&display=swap');

.NavLinks nav ul {
    display: flex;
    justify-content: flex-end;
    list-style-type: none;
    margin-right: 1em;
}

.NavLinks a:link {
    font-family: 'IM Fell English SC', serif;
    font-size: 30px;
    color: black;
    text-decoration: none;
    padding: 1em 0.5em;
}

.NavLinks a:visited {
    color: black;
}

.NavLinks a:hover {
    color: rgba(74, 100, 138, 0.959);
}

.NavLinks a:active {
    color: rgba(74, 100, 138, 0.959);
}

@media (max-width: 990px) {

    .NavLinks nav ul {
        margin: 0;
        width: 100%;
    }

    .NavLinks a:link {
        padding: 0.2em 1em;
    }

    .NavLinks a:hover {
        color: rgba(39, 53, 75, 0.959);
    }
}