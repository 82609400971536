@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IM+Fell+English+SC&display=swap');

.App {
  text-align: center;
}

body {
  background: url('./images/background2.jpg')center center no-repeat fixed;
  background-size: cover;
  font-family: 'Noto Serif', serif;
  color: rgb(255, 255, 255);
}

h1 {
  margin: 0.5em auto;
  font-family: 'IM Fell English SC', serif;
  font-size: 40px;
  color: rgb(37, 67, 95);
}

p {
  margin: auto;
  padding: 2em;
  font-weight: 600;
  max-width: 60vw;
  min-height: 40vh;
}