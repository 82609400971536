.NavBar .MobileNavigation {
    display: none;
}

@media (max-width: 990px) {
    .NavBar .Navigation {
        display: none;
    }

    .NavBar .MobileNavigation {
        display: flex;
        align-items: center;
    }

    .NavBar .MobileNavigation .Hamburger {
        position: absolute;
        right: 2%;
        top: 2%;
        cursor: pointer;
    }

    .NavBar .MobileNavigation .Hamburger:hover {
        color: rgba(74, 100, 138, 0.959);
    }

    .NavBar nav ul {
        display: flex;
        flex-direction: column;
        position: absolute;
        padding: 0.5em;
        top: 10%;
        right: 0;
        background: rgb(108, 126, 148);
        z-index: 999;
        opacity: 0.9;
    }

}