@import url(https://fonts.googleapis.com/css2?family=IM+Fell+English+SC&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IM+Fell+English+SC&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
    font-size: 32px;
}

h2 {
    font-size: 32px;
    text-decoration: underline;
    color: white;
}

h3 {
    font-size: 26px;
}

#uc-home-image, #uc-main-image, #ym-home-image, 
#ym-entry-image, #wn-home-image, #wn-answer-image  {
    max-height: 60vh;
    margin: 0 2em;
}

.fab {
    margin: 0.2em;
    font-size: 40px;
}

#vercel-logo {
    margin: 0 0.5em;
    width: 35px;
    border-radius: 5px;
    display: inline;
    text-align: center;
}

.app-link {
    display: inline;
    text-align: center;
    margin: 1em;
    color: rgb(255, 255, 255);
}

.app-link:hover, .vercel-link:hover {
    color: rgb(104, 165, 255);
}

.split {
    border-bottom: 2px solid black;
    width: 90%;
    margin: auto;
}

.contact_Contact__1xI98 a {
    display: inline;
    text-align: center;
    margin: 100px 40px;
    font-size: 40px;
    color: rgb(37, 67, 95);
}

.contact_Contact__1xI98 a:hover {
    color: rgb(20, 36, 51)
}

.NavBar_NavBar__2agkQ .NavBar_MobileNavigation__2FOU6 {
    display: none;
}

@media (max-width: 990px) {
    .NavBar_NavBar__2agkQ .NavBar_Navigation__30fZ5 {
        display: none;
    }

    .NavBar_NavBar__2agkQ .NavBar_MobileNavigation__2FOU6 {
        display: flex;
        align-items: center;
    }

    .NavBar_NavBar__2agkQ .NavBar_MobileNavigation__2FOU6 .NavBar_Hamburger__1dKYa {
        position: absolute;
        right: 2%;
        top: 2%;
        cursor: pointer;
    }

    .NavBar_NavBar__2agkQ .NavBar_MobileNavigation__2FOU6 .NavBar_Hamburger__1dKYa:hover {
        color: rgba(74, 100, 138, 0.959);
    }

    .NavBar_NavBar__2agkQ nav ul {
        display: flex;
        flex-direction: column;
        position: absolute;
        padding: 0.5em;
        top: 10%;
        right: 0;
        background: rgb(108, 126, 148);
        z-index: 999;
        opacity: 0.9;
    }

}
.NavLinks_NavLinks__3qUql nav ul {
    display: flex;
    justify-content: flex-end;
    list-style-type: none;
    margin-right: 1em;
}

.NavLinks_NavLinks__3qUql a:link {
    font-family: 'IM Fell English SC', serif;
    font-size: 30px;
    color: black;
    text-decoration: none;
    padding: 1em 0.5em;
}

.NavLinks_NavLinks__3qUql a:visited {
    color: black;
}

.NavLinks_NavLinks__3qUql a:hover {
    color: rgba(74, 100, 138, 0.959);
}

.NavLinks_NavLinks__3qUql a:active {
    color: rgba(74, 100, 138, 0.959);
}

@media (max-width: 990px) {

    .NavLinks_NavLinks__3qUql nav ul {
        margin: 0;
        width: 100%;
    }

    .NavLinks_NavLinks__3qUql a:link {
        padding: 0.2em 1em;
    }

    .NavLinks_NavLinks__3qUql a:hover {
        color: rgba(39, 53, 75, 0.959);
    }
}
.App {
  text-align: center;
}

body {
  background: url(/static/media/background2.651a8769.jpg)center center no-repeat fixed;
  background-size: cover;
  font-family: 'Noto Serif', serif;
  color: rgb(255, 255, 255);
}

h1 {
  margin: 0.5em auto;
  font-family: 'IM Fell English SC', serif;
  font-size: 40px;
  color: rgb(37, 67, 95);
}

p {
  margin: auto;
  padding: 2em;
  font-weight: 600;
  max-width: 60vw;
  min-height: 40vh;
}
